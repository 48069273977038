$image-path: "../../../../common/img/" !default;

.history{
  border-bottom: 4px solid #0f52ba;
  background-color: #f5f5f5;

  .th-history{
    background-color: aqua;
  }
  .col-timeline{
    .ant-row{
      height: 90vh;
      z-index: 900;
      .ant-btn-primary{
        background: #77bb21;
        border-color: #77bb21;
      }
    }
    .ant-col{
      width: 90%;
    }
    position: relative;
    overflow-y: auto;
  }
  
  //border-right: 4px solid #0f52ba;
  //border-left: 4px solid #0f52ba;
  //background-color: #f5f5f5;

  .row{
    width: 100%;

    .col-history > p{
      &::after{
        display: block;
        font-size: 1rem;
        font-weight: 400;
        -webkit-text-size-adjust: 100%;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        font-family: var(--mdb-font-roboto);
        line-height: 1.6;
        --mdb-gutter-x: 1.5rem;
        --mdb-gutter-y: 0;
        text-align: center !important;
        box-sizing: border-box;
        margin: 1rem 0 3rem 0;
        color: inherit;
        background-color: currentColor;
        border: 0;
        opacity: .25;
        height: 1px;
        content: "";
      }
    }
  }

  p {
    p{
      padding-left: 2.4rem;
    }
    ul{
      padding-left: 3.4rem;
    }
  }

  h1{
    &::before{
      display: inline-block;
      padding-right: .7rem;
      //background-image: url("#{$image-path}/content/water/drop.png");
      background-size: 4em;
      background-position: center;
      content: "0";
    }
    &::after{
      display: block;
      font-size: 1rem;
      font-weight: 400;
      -webkit-text-size-adjust: 100%;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      font-family: var(--mdb-font-roboto);
      line-height: 1.6;
      --mdb-gutter-x: 1.5rem;
      --mdb-gutter-y: 0;
      text-align: center !important;
      box-sizing: border-box;
      margin: 1rem 0;
      color: inherit;
      background-color: currentColor;
      border: 0;
      opacity: .25;
      height: 1px;
      content: "";
    }
  }

  p:nth-of-type(1){
    h1::before{
      content: "1.";
    }
  }
  p:nth-of-type(2){
    h1::before{
      content: "2.";
    }
  }
  p:nth-of-type(3){
    h1::before{
      content: "3.";
    }
  }
  p:nth-of-type(4){
    h1::before{
      content: "4.";
    }
  }
  p:nth-of-type(5){
    h1::before{
      content: "5.";
    }
  }
}


  /**
   * SPDX-License-Identifier: (EUPL-1.2)
   * Copyright © 2020 Werbeagentur Christian Aichner
   */
  