/**
 * @license
 * 
 * SPDX-FileCopyrightText: Copyright © 2021 snek.at
 * SPDX-License-Identifier: EUPL-1.2
 *
 * Use of this source code is governed by an EUPL-1.2 license that can be found
 * in the LICENSE file at https://snek.at/license
 */

 @media (min-width: 992px){
  
  .container{
    padding: 0 100px
  }
}

 html, body {
  height: unset;   
  width: unset;
 }

 body {
  background-color: #fff;
  overflow-y: scroll;
  min-height: 100vh;
  background-position: center bottom;
  background-repeat: repeat-x;
  background-size: 60vh;
  padding-bottom: 30vh;
  position: relative;
}

.cms-center{
  .public-DraftStyleDefault-ltr {
      text-align: center !important;
  }
}

h1{
  font-size: 2.5rem;
  font-weight: 400 !important;
}

h2{
  font-size: 2rem;
  font-weight: 400 !important;
}

h5{
  padding-top: 2rem;
  font-weight: 400 !important;
}

.breadcrumb-item{
  .nav-link{
    display: inline;
    padding: 0;
  }
}