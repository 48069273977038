// 6. Masks
// ===========================================================================

// Masks
$mask-overlay-transition                     : all .4s ease-in-out !default;
$mask-zoom-transition                        : all .2s linear !default;
$mask-zoom-transform                         : scale(1.1) !default;
$intro-video-transform                       : translateX(-50%) translateY(-50%) !default;
$intro-video-transition                      : 1s !default;
$mask-overlay-transition                     : all .4s ease-in-out !default;
$mask-zoom-transition                        : all .2s linear !default;
$mask-zoom-transform                         : scale(1.1) !default;
$intro-video-transform                       : translateX(-50%) translateY(-50%) !default;
$intro-video-transition                      : 1s !default;

$image-path                                  : "../../../common/img/" !default;

// General properties
.view {
  position: relative;
  overflow: hidden;
  cursor: default;
  .mask {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-attachment: fixed;
  }
  img, video {
    position: relative;
    display: block;
  }
  video {
    &.video-intro {
      top: 50%;
      left: 50%;
      z-index: -100;
      width: auto;
      min-width: 100%;
      height: auto;
      min-height: 100%;
      transition: $intro-video-transition opacity;
      transform: $intro-video-transform;
    }
  }
}

// Overlay
.overlay {
  .mask {
    opacity: 0;
    transition: $mask-overlay-transition;
    &:hover {
      opacity: 1;
    }
  }
}

// Zoom
.zoom {
  img, video {
    transition: $mask-zoom-transition;
  }
  &:hover {
    img, video {
      transform: $mask-zoom-transform;
    }
  }
}

// Patterns
$patterns: (
  1: "01",
  2: "02",
  3: "03",
  4: "04",
  5: "05",
  6: "06",
  7: "07",
  8: "08",
  9: "09"
) !default;

@each $no, $filename in $patterns {
  .pattern-#{$no} {
    background: url("#{$image-path}/overlays/#{$filename}.png");
    background-attachment: fixed;
  }
}
