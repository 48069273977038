$image-path: "../../../../common/img/" !default;

.connect{
  border-bottom: 4px solid #0f52ba;
  //border-right: 4px solid #0f52ba;
  border-left: 4px solid #0f52ba;
  background-color: #f5f5f5;

  .row{
    width: 100%;
  }

  // p {
  //   p{
  //     padding-left: 2.4rem;
  //   }
  //   ul{
  //     padding-left: 3.4rem;
  //   }
  // }

  .DraftEditor-root{
    h2 span span{
      &::before{
        display: inline-block;
        padding-right: .7rem;
        //background-image: url("#{$image-path}/content/water/drop.png");
        background-size: 4em;
        background-position: center;
        content: "";
      }
    }
  }
  .col-connect-1{
    h2:nth-of-type(1) span{
      span::before{
        content: "1.";
      }
    }
    h2:nth-of-type(2) span{
      span::before{
        content: "2.";
      }
    }
    h2:nth-of-type(3) span{
      span::before{
        content: "3.";
      }
    }
    h2:nth-of-type(4) span{
      span::before{
        content: "4.";
      }
    }
    h2:nth-of-type(5) span{
      span::before{
        content: "5.";
      }
    }
  }

  .col-connect-2{
    h2:nth-of-type(1) span{
      span::before{
        content: "3.";
      }
    }
    h2:nth-of-type(2) span{
      span::before{
        content: "4.";
      }
    }
    h2:nth-of-type(3) span{
      span::before{
        content: "5.";
      }
    }
    h2:nth-of-type(4) span{
      span::before{
        content: "6.";
      }
    }
    h2:nth-of-type(5) span{
      span::before{
        content: "7.";
      }
    }
  }
}

  /**
   * SPDX-License-Identifier: (EUPL-1.2)
   * Copyright © 2020 Werbeagentur Christian Aichner
   */
  