$lupiBlue: #1976d2;
$lupiDarkBlue: darken($lupiBlue, 10%);
$lupiLightBlue: lighten($lupiBlue, 10%);
$gold: #d2a968;
$lightgold: #f9e093;
$green: #01ad79;

.navbar.scrolling-navbar.top-nav-collapse{
  background-color: white;
}

.navlogo {
  width: 70px;
  margin: 0;
  height: auto;
  display: block;
  //margin-left: 0.2em;
}

.navtitle {
  //font-size: 10em;
  //line-height: 1.1em;
  //letter-spacing: 0.01em;
  text-transform: uppercase;
  margin: 0;
  font-weight: bold;
  letter-spacing: 0.04em;
}

// .navbar {
//   z-index: 1009 !important;
// }

@media only screen and (max-width: 1440px) {
  .navbar {
    box-shadow: none !important;
    background-color: #f7f7f7;

    .navbar-nav {
      margin-right: 1em;
      font-size: larger;
  
      .nav-item {
        background-color: transparent;
      }
  
      .nav-link {
        background-color: transparent;
      }
    }
  
    .counter {
      display: inline-block;
      position: initial;
      background: $green;
      box-shadow: none;
  
      &.mobile {
        position: absolute;
        bottom: auto;
        top: auto;
        right: auto;
        left: 13px;
        margin: auto;
      }
    }
  }
}

@media only screen and (min-width: 1440px) {
  .navbar {
    box-shadow: none !important;
    background-color: #f7f7f700;
  
    .navbar-nav {
      margin-right: 1em;
      font-size: larger;
  
      .nav-item {
        background-color: transparent;
      }
  
      .nav-link {
        background-color: transparent;
      }
    }
  
    .counter {
      display: inline-block;
      position: initial;
      background: $green;
      box-shadow: none;
  
      &.mobile {
        position: absolute;
        bottom: auto;
        top: auto;
        right: auto;
        left: 13px;
        margin: auto;
      }
    }
  }
}

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2020 Werbeagentur Christian Aichner
 */